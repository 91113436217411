
import Vue from "vue";
import SpinnerModule from "@/store/modules/spinnerModule";
import SnackModule from "@/store/modules/errorSnackbar";
import { ApiErrorResponse, AxiosHttpClient, getUrl, ApiList } from "apd.apiconnectors";
import { mdiFileDocument, mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { DataTableHeader } from "vuetify";
import { Visita, FiltroVisitas } from "apd.models";
import { ApiVisitas } from "apd.apiconnectors/dist/apiVisitas";

export default Vue.extend({
  name: "ReporteVisitas",
  components: {
    SelectorCajeros: () => import("@/components/selectores/cajeros.vue"),
    Boton: () => import("@/components/botones/boton.vue"),
    DialogReportes: () => import("@/components/dialogReportes.vue"),
  },
  filters: {
    toMoney(value: number) {
      return `$${value.toFixed(2).replace(/(\\d)(?=(\\d{3})+(?!\\d))/g, "$1,")}`;
    },
  },
  data() {
    return {
      validacion: false as boolean,
      titulo: "Reporte de visitas" as string,
      fechaInicio: "" as string,
      fechaFin: "" as string,
      cajero: "" as string | string[] | null,
      filtro: {} as FiltroVisitas,
      data: null as Visita[] | null,
      icons: {
        ref: mdiFileDocument,
        up: mdiChevronUp,
        down: mdiChevronDown,
      } as Record<string, string>,
      headersOps: [
        {
          text: "Fecha",
          value: "created",
          align: "center",
          sortable: true,
        },
        {
          text: "Cajero",
          value: "cajero",
          align: "center",
          sortable: true,
        },
        {
          text: "Técnico PD",
          value: "usuario",
          align: "center",
          sortable: true,
        },
        {
          text: "Técnico TV",
          value: "nombreAgente",
          align: "center",
          sortable: true,
        },
        {
          text: "Visita",
          value: "conceptoVisita",
          align: "center",
          sortable: true,
        },
        {
          text: "Valor",
          value: "tipoVisita",
          align: "center",
          sortable: true,
        },
        {
          text: "Valor",
          value: "cajero",
          align: "center",
          sortable: true,
        },
      ] as DataTableHeader[],
    };
  },
  computed: {
    visitas(): Visita[] {
      if (this.data) {
        return this.data;
      }
      return [];
    },
  },
  methods: {
    async LoadVisitas() {
      const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSistemaPagos = new ApiVisitas(client);

      try {
        SpinnerModule.Show();
        this.data = (await apiSistemaPagos.ConsultarVisitas(this.filtro as FiltroVisitas)) as Visita[];
      } catch (e) {
        const err = e as ApiErrorResponse;
        SnackModule.Show(err.apiError.mensajeUsuario);
        this.data = null;
      } finally {
        SpinnerModule.Hide();
      }
    },
    changeCajero(data: string[] | undefined) {
      if (this.filtro) {
        this.filtro.cajeros = data;
        this.validacion = !!(this.filtro.fechaInicial && this.filtro.fechaFinal && this.filtro.cajeros);
      }
    },
    changeFechaInicio(data: Date) {
      if (this.filtro) {
        this.filtro.fechaInicial = data.toString();
        this.validacion = !!(this.filtro.fechaInicial && this.filtro.fechaFinal && this.filtro.cajeros);
      }
    },
    changeFechaFin(data: Date) {
      if (this.filtro) {
        this.filtro.fechaFinal = data.toString();
        this.validacion = !!(this.filtro.fechaInicial && this.filtro.fechaFinal && this.filtro.cajeros);
      }
    },
    async Visualizar(dialog: boolean, show: (value: boolean) => void) {
      await this.LoadVisitas();

      if (this.data) {
        show(!dialog);
      }
    },
    // async Exportar() {
    //   const client = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
    //   client.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    //   const apiSistemaPagos = new ApiSistemaPagos(client);
    //
    //   try {
    //     SpinnerModule.Show();
    //     let data = "";
    //
    //     data = (await apiSistemaPagos.ConsultarOperacionesCorteTarjetaExcel(this.dia, this.afiliacion)) as string;
    //
    //     const blob = this.b64toBlob(data, "application/vnd.ms-excel");
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", "CorteTC.xlsx");
    //     document.body.appendChild(link);
    //     link.click();
    //   } catch (e) {
    //     const err = e as ApiErrorResponse;
    //     SnackModule.Show(err.apiError.mensajeUsuario);
    //     this.data = null;
    //   } finally {
    //     SpinnerModule.Hide();
    //   }
    // },
    // b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
    //   const byteCharacters = atob(b64Data);
    //   const byteArrays = [];
    //
    //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //     const slice = byteCharacters.slice(offset, offset + sliceSize);
    //
    //     const byteNumbers = new Array(slice.length);
    //     for (let i = 0; i < slice.length; i++) {
    //       byteNumbers[i] = slice.charCodeAt(i);
    //     }
    //
    //     const byteArray = new Uint8Array(byteNumbers);
    //     byteArrays.push(byteArray);
    //   }
    //
    //   const blob = new Blob(byteArrays, { type: contentType });
    //   return blob;
    // },
  },
});
